/** @format */

// /** @format */

// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Home from './Pages/Home';
// import MetrithaServices from './Pages/MetrithaServices';
// import MetrithaAssociates from './Pages/MetrithaAssociates';

// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route
//           path='/'
//           element={<Home />}
//         />
//         <Route
//           path='/metrithaServices'
//           element={<MetrithaServices />}
//         />
//         <Route
//           path='/MetrithaAssociates'
//           element={<MetrithaAssociates />}
//         />
//       </Routes>
//     </Router>
//   );
// }

// export default App;

import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./Pages/Home";
import MetrithaServices from "./Pages/MetrithaServices";
import MetrithaAssociates from "./Pages/MetrithaAssociates";
import AdminLogin from "./Pages/Admin/AdminLogin";
import AdminDashboard from "./Pages/Admin/AdminDashboard";
import GoogleTranslateWidget from "./Components/GoogleTranslate";
function App() {
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);

  const handleLogin = () => {
    setIsAdminAuthenticated(true);
  };

  return (
    <Router>
      <GoogleTranslateWidget />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/metrithaServices" element={<MetrithaServices />} />
        <Route path="/MetrithaAssociates" element={<MetrithaAssociates />} />
        <Route
          path="/admin"
          element={
            isAdminAuthenticated ? (
              <Navigate to="/admin/dashboard" />
            ) : (
              <AdminLogin onLogin={handleLogin} />
            )
          }
        />
        <Route
          path="/admin/dashboard"
          element={
            isAdminAuthenticated ? <AdminDashboard /> : <Navigate to="/admin" />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
