/** @format */

import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import GTranslateRoundedIcon from "@mui/icons-material/GTranslateRounded";
import mainlogo from "../Assets/logo.png";
import { useNavigate } from "react-router-dom";

const Navbar = ({ mainHeading, isAdmin }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigation = useNavigate();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleHome = () => {
    navigation("/");
  };

  const handleMetrithaServices = () => {
    navigation("/MetrithaServices");
  };

  const handleMetrithaAssociates = () => {
    navigation("/MetrithaAssociates");
  };

  const handleAdminDashboard = () => {
    navigation("/admin");
  };

  return (
    <AppBar
      position="static"
      sx={{ boxShadow: "none", backgroundColor: "transparent" }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginX: "2%",
          marginY: "1%",
          boxShadow: "none",
        }}
      >
        {/* Hamburger menu for smaller screens */}
        <Box sx={{ display: { xs: "block", sm: "block" } }}>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            size="large"
            onClick={handleDrawerToggle}
            sx={{ color: "#f0f0f0" }}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        {/* Logo */}
        <Box sx={{ display: { xs: "block", sm: "block" } }}>
          <img
            src={mainlogo}
            alt="logo"
            style={{ height: "100%", width: "100%" }}
          />
        </Box>

        {/* Right Icon Button */}
        <Box sx={{ display: { xs: "block", sm: "block" } }}>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            size="large"
            onClick={handleDrawerToggle}
            sx={{ color: "#f0f0f0" }}
          >
            <GTranslateRoundedIcon />
          </IconButton>
        </Box>
      </Toolbar>

      {/* Drawer for mobile */}
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        <List
          sx={{
            width: 300,
            backgroundColor: "#331D48",
            height: "100%",
          }}
        >
          {isAdmin ? (
            <ListItem
              button
              onClick={handleAdminDashboard}
              sx={{
                border: "2px dashed #666",
                borderRadius: "8px",
                my: 10,
                p: 2,
                backgroundColor: "#f0f0f0",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
              }}
            >
              <ListItemText
                primary="Admin Dashboard"
                sx={{
                  fontFamily: "Poppins",
                  color: "#333",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              />
            </ListItem>
          ) : (
            <>
              <ListItem
                button
                onClick={handleHome}
                sx={{
                  border: "2px dashed #666",
                  borderRadius: "8px",
                  mb: 2,
                  p: 2,
                  backgroundColor: "#f0f0f0",
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
              >
                <ListItemText
                  primary="Home"
                  sx={{
                    fontFamily: "Poppins",
                    color: "#333",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                />
              </ListItem>
              <ListItem
                button
                onClick={handleMetrithaServices}
                sx={{
                  border: "2px dashed #666",
                  borderRadius: "8px",
                  mb: 2,
                  p: 2,
                  backgroundColor: "#f0f0f0",
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
              >
                <ListItemText
                  primary="Metritha Services"
                  sx={{
                    fontFamily: "Poppins",
                    color: "#333",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                />
              </ListItem>

              <ListItem
                button
                onClick={handleMetrithaAssociates}
                sx={{
                  border: "2px dashed #666",
                  borderRadius: "8px",
                  mb: 2,
                  p: 2,
                  backgroundColor: "#f0f0f0",
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
              >
                <ListItemText
                  primary="Metritha Network"
                  sx={{
                    fontFamily: "Poppins",
                    color: "#333",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                />
              </ListItem>
            </>
          )}
        </List>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
