/** @format */

import React, { useState } from 'react';
import Navbar from '../Components/NavBar';
import backgroundVideo from '../Assets/BGVideo.mp4';
import NewButton from '../Components/NewButton';
import bottomImg from '../Assets/bottomImg.png';
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import img7 from '../Assets/img7.webp';

import ContactBtn from '../Components/ContactBtn';

import Footer from '../Components/Footer';

const textData = [
  'Prepare the economic valuation of the company',
  'Assist in defining the transaction terms and the values involved ',
  'Develop the company information memorandum',
  'Identify investors and/or target companies',
  'Negotiate with investors and/or target companies.',
  'Assist in drafting contractual clauses',
  'Coordinate the due diligence process',
  'Oversee the closing of the transaction.',
  'Assist in the preparation of contracts and agreements related to the transaction',
];
const bgColors = [
  'purple',
  'purple',
  'purple',
  'purple',
  'purple',
  'purple',
  'purple',
  'purple',
  'purple',
];
const MetrithaServices = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Handle sliding text and background color
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? textData.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === textData.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <>
      <Box sx={{ position: 'relative', height: '100vh', width: '100%' }}>
        <video
          autoPlay
          loop
          muted
          style={{
            position: 'relative',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            objectFit: 'cover',
            zIndex: -1,
          }}>
          <source
            src={backgroundVideo}
            type='video/mp4'
          />
          Your browser does not support the video tag.
        </video>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
            mt: 2,
          }}>
          <Navbar mainHeading='Admin Dashboard' />

          <Grid
            container
            spacing={2}
            height={'50%'}>
            <Grid
              item
              xs={0}
              sm={3}
              md={3}
              lg={3}></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{ textAlign: 'center' }}>
              <Typography
                variant='h1'
                sx={{
                  fontFamily: 'Poppins',
                  color: '#fff',
                  fontWeight: 'thin',
                  fontSize: '5rem',
                  letterSpacing: '1px',
                  lineHeight: '1.5',
                }}>
                Metritha Services
              </Typography>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <NewButton
              title='Get Started'
              bgColor='#331D48'
              onClick={undefined}
            />
          </Grid>
        </Box>
      </Box>
      <Grid
        container
        backgroundColor={'#331D48'}
        spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          textAlign={'center'}
          sx={{
            mt: 4,
            mb: 2,
          }}>
          <Typography
            sx={{
              color: 'white', // Set font color to white
              fontFamily: 'Poppins',
              letterSpacing: '2px',
              fontSize: '2.5rem',
              fontWeight: '300',
              fontFamily: 'Poppins',
              padding: '10px 40px',
              letterSpacing: '2px',
            }}>
            Mergers & Acquisitions
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        textAlign={'center'}
        flexDirection={'row'}
        sx={{
          pt: 4,
          pb: 8,
          backgroundColor: '#331D48',
        }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}>
          <Typography
            variant='body2'
            sx={{
              fontFamily: 'Poppins',
              fontSize: { xs: '1rem', sm: '1rem', md: '1.2rem' },
              fontWeight: '300',
              fontFamily: 'Poppins',
              color: '#f0f0f0',
              textAlign: 'left',
              py: { xs: 0, sm: 0, md: 10 },
              px: { xs: 2, sm: 4, md: 10 },
            }}>
            Companies seek growth, by increasing their market presence, locally
            and regionally, by achieving greater efficiencies and improving
            results.
            <br />
            <br />
            Shareholders’ aim to enhance the value and liquidity of their stake
            holdings. These objectives drive sector consolidations, strategic
            actions and succession decisions that energize the M&A market.
            <br />
            <br />
            Metritha has experience in both sell-side and buy-side transactions,
            providing the following services:
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}>
          <Box
            sx={{
              position: 'relative',
              width: 0,
              height: 0,
              borderLeft: '140px solid transparent', // Left side
              borderRight: '140px solid transparent', // Right side
              borderBottom: `280px solid ${bgColors[currentIndex]}`,

              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}>
            <Typography
              variant='h6'
              sx={{
                position: 'absolute',
                top: 150, // Center vertically
                left: '50%', // Center horizontally
                transform: 'translate(-50%, -50%)',
                color: 'white',
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: '0.7rem',
                fontWeight: '200',
              }}>
              {textData[currentIndex]}
            </Typography>

            {/* Left Arrow Button */}
            {currentIndex > 0 && (
              <IconButton
                onClick={handlePrev}
                sx={{
                  position: 'absolute',
                  top: { xs: 135, sm: 145, md: 155, lg: 165 },
                  left: {
                    xs: '-140px',
                    sm: '-150px',
                    md: '-160px',
                    lg: '-170px',
                  },
                  transform: 'translateY(-50%)',
                  color: 'black',
                }}>
                <ArrowCircleLeftIcon sx={{ fontSize: 54, color: '#f0f0f0' }} />
              </IconButton>
            )}
            {/* Right Arrow Button */}
            {currentIndex < textData.length - 1 && (
              <IconButton
                onClick={handleNext}
                sx={{
                  position: 'absolute',
                  top: { xs: 135, sm: 145, md: 155, lg: 165 },
                  right: {
                    xs: '-140px',
                    sm: '-150px',
                    md: '-160px',
                    lg: '-170px',
                  },
                  transform: 'translateY(-50%)',
                  color: 'black',
                }}>
                <ArrowCircleRightIcon sx={{ fontSize: 54, color: '#f0f0f0' }} />
              </IconButton>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        textAlign={'center'}
        backgroundColor={'#331D48'}
        sx={{
          Pt: 4,
          Pb: { xs: 1, sm: 2, md: 4, lg: 4 },
        }}>
        <Typography
          sx={{
            color: 'white', // Set font color to white
            fontFamily: 'Poppins',
            letterSpacing: '2px',
            fontSize: '2rem',
            fontWeight: '300',
            fontFamily: 'Poppins',
            padding: '15px 40px',
            letterSpacing: '2px',
            mb: 2,
          }}>
          Equity Participation
        </Typography>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: '#331D48',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          py: { xs: 1, sm: 2, md: 4, lg: 4 },
          px: { xs: 0, sm: 0, md: 6, lg: 6 },
        }}>
        <Grid
          xs={12}
          sm={6}
          md={6}
          lg={6}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: { xs: '0.8rem', sm: '0.8rem', md: '1rem' },
              fontWeight: '300',
              fontFamily: 'Poppins',
              letterSpacing: '2px',
              color: '#f0f0f0',
              textAlign: 'left',
              padding: '10px 40px',
            }}>
            Companies in a growth face require long-term resources that can be
            provided by new investors. The following business activities are
            typically financed by equity investors:
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sm={6}
          md={6}
          lg={6}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: { xs: '0.8rem', sm: '0.8rem', md: '1rem' },
              fontWeight: '300',
              fontFamily: 'Poppins',
              letterSpacing: '2px',
              color: '#f0f0f0',
              textAlign: 'left',
              padding: '10px 45px',
            }}>
            Metritha has extensive experience in raising capital from
            institutional investors, such as private equity funds and family
            offices, and provides the following services:
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: '#331D48',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
        }}>
        <Grid
          xs={12}
          sm={6}
          md={6}
          lg={6}>
          <List sx={{ px: { xs: 0, sm: 0, md: 8, lg: 10 } }}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: '2px',
                      fontWeight: '300',
                      color: '#f0f0f0',
                      textAlign: { xs: 'center', md: 'left' },
                    }}>
                    Acquisitions of companies
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: '2px',
                      fontWeight: '300',
                      color: '#f0f0f0',
                      textAlign: { xs: 'center', md: 'left' },
                    }}>
                    Strengthening of capital structure
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: '2px',
                      fontWeight: '300',
                      color: '#f0f0f0',
                      textAlign: { xs: 'center', md: 'left' },
                    }}>
                    Buyout of partner stakes
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: '2px',
                      fontWeight: '300',
                      color: '#f0f0f0',
                      textAlign: { xs: 'center', md: 'left' },
                    }}>
                    Expansion of product lines and market regions
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: { xs: 12, sm: 12, md: 14, lg: 18 },
                      letterSpacing: '2px',
                      fontWeight: '300',
                      color: '#f0f0f0',
                      textAlign: { xs: 'center', md: 'left' },
                    }}>
                    Succession processes
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Grid>
        <Grid
          xs={12}
          sm={6}
          md={6}
          lg={6}>
          <List sx={{ padding: 5 }}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: '2px',
                      fontWeight: '300',
                      color: '#f0f0f0',
                      textAlign: { xs: 'center', md: 'left' },
                    }}>
                    Analyze the company's situation and future prospects
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: '2px',
                      fontWeight: '300',
                      color: '#f0f0f0',
                      textAlign: { xs: 'center', md: 'left' },
                    }}>
                    Prepare the economic valuation of the business
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: '2px',
                      fontWeight: '300',
                      color: '#f0f0f0',
                      textAlign: { xs: 'center', md: 'left' },
                    }}>
                    Develop an information memorandum for investors
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: '2px',
                      fontWeight: '300',
                      color: '#f0f0f0',
                      textAlign: { xs: 'center', md: 'left' },
                    }}>
                    Identify suitable investors to provide capital to the
                    company
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: '2px',
                      fontWeight: '300',
                      color: '#f0f0f0',
                      textAlign: { xs: 'center', md: 'left' },
                    }}>
                    Negotiate with these investors regarding their stake in the
                    company
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: '2px',
                      fontWeight: '300',
                      color: '#f0f0f0',
                      textAlign: { xs: 'center', md: 'left' },
                    }}>
                    Assist the company in due diligence and participation
                    agreements
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: { xs: 12, sm: 12, md: 14, lg: 18 },
                      letterSpacing: '2px',
                      fontWeight: '300',
                      color: '#f0f0f0',
                      textAlign: { xs: 'center', md: 'left' },
                    }}>
                    Finalize the investment transaction with investors and
                    advise on contractual conditions.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
      {/* -------------------------------------------- */}
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          backgroundColor: '#331D48',
          px: { xs: 2, sm: 4, md: 0, lg: 0 },
        }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: 4,
          }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              pt: { xs: 1, sm: 2, md: 2, lg: 4 },
              pb: { xs: 1, sm: 2, md: 2, lg: 4 },
            }}>
            <Typography
              sx={{
                color: 'white',
                fontFamily: 'Poppins',
                letterSpacing: '2px',
                fontSize: '2rem',
                fontWeight: '300',
                fontFamily: 'Poppins',
                letterSpacing: '2px',
              }}>
              Long Term Financing
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            display={'flex'}
            // justifyContent={'center'}
            // alignItems={'center'}
            // textAlign={'center'}
            sx={{
              mt: 0,
              mb: 0,
            }}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: { xs: 10, sm: 12, md: 14, lg: 20 },
                fontWeight: '300',
                letterSpacing: '2px',
                color: '#f0f0f0',
              }}>
              During expansion periods, companies need to invest in civil works,
              machinery, equipment, facilities, and technology, in addition to
              requiring working capital to support these investments.
              <br />
              <br />
              These resources can be obtained through long-term financing lines
              from international and national development institutions (IFC, BID
              Invest, CAF, DEG, Proparco).
              <br />
              <br />
              Metritha fully structures these long-term financing operations
              with these institutions. The process involves:
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={10}
            sx={{ width: '100%' }}>
            <img
              src={img7}
              alt='img7'
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'contain',
              }}
            />
          </Grid>
        </Grid>
        {/* <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: { xs: 10, sm: 12, md: 14, lg: 20 },
              lineHeight: 2,
              letterSpacing: '2px',
              color: '#f0f0f0',
              fontWeight: '300',
              textAlign: { xs: 'center', md: 'left' },
              letterSpacing: '2px',
              width: '100%',
            }}>
            Analyzing the company's needs
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: { xs: 10, sm: 12, md: 14, lg: 20 },
              lineHeight: 2,
              letterSpacing: '2px',
              color: '#f0f0f0',
              fontWeight: '300',
              textAlign: { xs: 'center', md: 'left' },
              letterSpacing: '2px',
              width: '100%',
            }}>
            Defining the institution, financing line, amount, and appropriate
            guarantees
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: { xs: 10, sm: 12, md: 14, lg: 20 },
              lineHeight: 2,
              letterSpacing: '2px',
              color: '#f0f0f0',
              fontWeight: '300',
              textAlign: { xs: 'center', md: 'left' },

              letterSpacing: '2px',
              width: '100%',
            }}>
            Preparing the feasibility project tailored to each institution
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: { xs: 10, sm: 12, md: 14, lg: 20 },
              lineHeight: 2,
              letterSpacing: '2px',
              color: '#f0f0f0',
              fontWeight: '300',
              textAlign: { xs: 'center', md: 'left' },

              letterSpacing: '2px',
              width: '100%',
            }}>
            Overseeing the entire analysis process
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: { xs: 10, sm: 12, md: 14, lg: 20 },
              lineHeight: 2,
              letterSpacing: '2px',
              color: '#f0f0f0',
              fontWeight: '300',
              textAlign: { xs: 'center', md: 'left' },

              letterSpacing: '2px',
              width: '100%',
            }}>
            Negotiating with the institution on conditions, costs, terms, and
            guarantees
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: { xs: 10, sm: 12, md: 14, lg: 20 },
              lineHeight: 2,
              letterSpacing: '2px',
              color: '#f0f0f0',
              fontWeight: '300',
              textAlign: { xs: 'center', md: 'left' },
              letterSpacing: '2px',
              width: '100%',
            }}>
            Assisting with the contracting
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: { xs: 10, sm: 12, md: 14, lg: 20 },
              lineHeight: 2,
              letterSpacing: '2px',
              color: '#f0f0f0',
              fontWeight: '300',
              textAlign: { xs: 'center', md: 'left' },
              width: '100%',
            }}>
            Monitoring the release of funds to the company
          </Typography>
        </Grid> */}
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}>
          <ul
            style={{
              listStyleType: 'disc',
              paddingLeft: '20px',
              color: '#f0f0f0',
              fontFamily: 'Poppins',
              fontWeight: '300',
              fontSize: { xs: 10, sm: 12, md: 14, lg: 20 },
              lineHeight: { xs: 1, sm: 2, md: 3, lg: 20 },
              letterSpacing: '2px',
            }}>
            <li style={{ marginBottom: '15px' }}>
              Analyzing the company's needs
            </li>
            <li style={{ marginBottom: '15px' }}>
              Defining the institution, financing line, amount, and appropriate
              guarantees
            </li>
            <li style={{ marginBottom: '15px' }}>
              Preparing the feasibility project tailored to each institution
            </li>
            <li style={{ marginBottom: '15px' }}>
              Overseeing the entire analysis process
            </li>
            <li style={{ marginBottom: '15px' }}>
              Negotiating with the institution on conditions, costs, terms, and
              guarantees
            </li>
            <li style={{ marginBottom: '15px' }}>
              Assisting with the contracting
            </li>
            <li style={{ marginBottom: '15px' }}>
              Monitoring the release of funds to the company
            </li>
          </ul>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        textAlign={'center'}
        backgroundColor={'#331D48'}
        sx={{
          Pt: 4,
          Pb: { xs: 1, sm: 2, md: 4, lg: 4 },
        }}>
        <Typography
          sx={{
            color: 'white', // Set font color to white
            fontFamily: 'Poppins',
            letterSpacing: '2px',
            fontSize: '2rem',
            fontWeight: '300',
            fontFamily: 'Poppins',
            padding: '20px 40px',
            letterSpacing: '2px',
          }}>
          Valuations & Projects
        </Typography>
      </Grid>
      <Grid
        container
        backgroundColor={'#331D48'}
        flexDirection={'row'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={6}
          justifyContent={'center'}
          alignItems={'center'}
          textAlign={'center'}
          sx={{
            // mt: 4,
            pb: 2,
          }}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: { xs: '0.8rem', sm: '0.8rem', md: '1rem' },
              fontWeight: '300',
              fontFamily: 'Poppins',
              letterSpacing: '2px',
              color: '#f0f0f0',
              padding: '10px 40px',
              marginBottom: '20px',
            }}>
            To provide the aforementioned services, Metritha has developed
            technical skills that enable the preparation of various studies.
            <br />
            <br />
            The following works support the services offered and can also be
            provided independently to companies:
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        display='flex'
        justifyContent='space-evenly'
        backgroundColor='#331D48'
        alignItems='center'
        flexDirection={{ xs: 'column', sm: 'row', md: 'row' }}>
        <Grid
          item
          xs={0}
          sm={2}
          md={2}></Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection={{ xs: 'column', sm: 'row', md: 'row' }}
          gap={{ xs: 2, sm: 0, md: 0 }}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: { xs: 14, md: 16 }, // Font size responsive
              letterSpacing: '2px',
              color: '#f0f0f0',
              padding: { xs: '10px 20px', md: '10px 40px' },
              textAlign: { xs: 'center', md: 'left' },
            }}>
            Economic valuation of companies and businesses
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: { xs: 14, md: 16 },
              letterSpacing: '2px',
              color: '#f0f0f0',
              padding: { xs: '10px 20px', md: '10px 40px' },
              textAlign: { xs: 'center', md: 'left' },
            }}>
            Business plans
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: { xs: 14, md: 16 },
              letterSpacing: '2px',
              color: '#f0f0f0',
              padding: { xs: '10px 20px', md: '10px 40px' },
              textAlign: { xs: 'center', md: 'left' },
            }}>
            Buyout of partner stakes
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: { xs: 14, md: 16 },
              letterSpacing: '2px',
              color: '#f0f0f0',
              padding: { xs: '10px 20px', md: '10px 40px' },
              textAlign: { xs: 'center', md: 'left' },
            }}>
            Economic-financial feasibility studies
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: { xs: 14, md: 16 },
              letterSpacing: '2px',
              color: '#f0f0f0',
              paddingRight: 5,
              padding: { xs: '10px 20px', md: '10px 40px' },
              textAlign: { xs: 'center', md: 'left' },
            }}>
            Company and business diagnostics
          </Typography>
        </Grid>
        <Grid
          item
          xs={0}
          sm={0}
          md={2}></Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        backgroundColor={'#331D48'}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            position: 'relative',
          }}>
          {/* Image */}
          <img
            src={bottomImg}
            alt='logo'
            style={{
              width: '80%',
              height: '80%',
              maxWidth: '100%',
            }}
          />

          {/* Overlay Content */}
          <Box
            sx={{
              position: 'absolute',
              top: '30%', // Adjust to center better on small screens
              left: '50%',
              transform: 'translate(-50%, -50%)', // Adjust position
              textAlign: 'center',
              color: '#fff', // Text color
              zIndex: 1, // Ensure text is above the image
              padding: { xs: '10px', sm: '20px' }, // Padding for mobile
              width: { xs: '100%', sm: '80%', md: '60%' }, // Responsive width
            }}>
            <Typography
              variant='h1'
              sx={{
                fontWeight: 'lighter',
                fontFamily: 'Poppins',
                color: '#f0f0f0',
                fontSize: { xs: '12px', sm: '20px', md: '25px', lg: '40px' },
                letterSpacing: { xs: '2px', sm: '3px', md: '5px' },
              }}>
              Interested in our portfolio?
            </Typography>
          </Box>

          <Box
            sx={{
              position: 'absolute',
              top: { xs: '70%', sm: '65%', md: '60%' },
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
              width: { xs: '80%', sm: '55%', md: '90%', lg: '100%' },
              // height: '50%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '15px',
            }}>
            <ContactBtn
              open={open}
              handleClose={handleClose}
              setWidth={{ xs: '10%', sm: '10%', md: '50%', lg: '100%' }}
            />

            <Typography
              variant='body1'
              sx={{
                fontFamily: 'Poppins',
                color: '#f0f0f0',
                marginTop: '15px',
                display: {
                  xs: 'none',
                  sm: 'flex',
                  md: 'flex',
                  lg: 'flex',
                },
              }}>
              We will call you back in..
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default MetrithaServices;
