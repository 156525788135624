/** @format */

import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { LinkedIn } from '@mui/icons-material';
import mainlogo from '../Assets/logo.png';

const Footer = () => {
  return (
    <Box
      sx={{
        p: { xs: 2, sm: 3, md: 4 }, // Responsive padding
        marginX: 'auto',
        px: { xs: 2, sm: 4, md: 22 }, // Responsive horizontal padding
        backgroundColor: '#331D48',
      }}>
      <Grid
        container
        spacing={3}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: {
            xs: 'center',
            sm: 'flex-start',
            md: 'center',
          },
        }}>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: { xs: 'center', sm: 'flex-start', md: 'felx-start' }, // Center text on small screens, align left on larger
          }}>
          <img
            src={mainlogo}
            alt='logo'
            style={{
              width: '200px',
              height: 'auto',
              objectFit: 'contain',
            }}
          />
          <Typography
            variant='h6'
            sx={{
              fontFamily: 'Poppins',
              color: '#f0f0f0',
              fontWeight: '300',
              fontSize: { xs: '12px', sm: '12px', md: '16px', lg: '18px' },
              paddingY: { xs: 2, sm: 1, md: 2 },
              textAlign: { xs: 'center', sm: 'left' },
            }}>
            Metritha Capital Partners LLC. Todos los derechos
            reservados.Metritha LLC and our services are Registered Trademarks.
          </Typography>
          <Typography
            variant='body1'
            sx={{
              fontFamily: 'Poppins',
              color: '#f0f0f0',
              fontWeight: '300',
              mt: 2,
              fontSize: { xs: '14px', sm: '16px', md: '18px' },

              textAlign: { xs: 'center', sm: 'left' },
            }}>
            Privacy and Cookie Policy - 2024
          </Typography>
        </Grid>

        {/* Second Section: Additional Grid for more content */}
        <Grid
          item
          xs={12}
          sm={3}
          md={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: { xs: 'center', sm: 'flex-start', md: 'left' },
          }}>
          <Typography
            variant='body1'
            sx={{
              fontFamily: 'Poppins',
              color: '#f0f0f0',
              fontWeight: '500',
              fontSize: { xs: '14px', sm: '16px', md: '18px' },
              letterSpacing: '1px',
              mb: 2,
              textAlign: { xs: 'center', sm: 'left', md: 'left' },
            }}>
            Quick Links
          </Typography>
          <Typography
            variant='body1'
            sx={{
              fontFamily: 'Poppins',
              color: '#f0f0f0',
              fontWeight: '300',
              fontSize: { xs: '14px', sm: '16px', md: '18px' },
              letterSpacing: '1px',
              textAlign: { xs: 'center', sm: 'left', md: 'left' },
            }}>
            Services
          </Typography>
          <Typography
            variant='body1'
            sx={{
              fontFamily: 'Poppins',
              color: '#f0f0f0',
              fontWeight: '300',
              fontSize: { xs: '14px', sm: '16px', md: '18px' },
              letterSpacing: '1px',
              textAlign: { xs: 'center', sm: 'left', md: 'left' },
            }}>
            Partners
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          md={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: { xs: 'center', sm: 'flex-start', md: 'left' },
          }}>
          <Typography
            variant='body1'
            sx={{
              fontFamily: 'Poppins',
              color: '#f0f0f0',
              fontWeight: '500',
              fontSize: { xs: '14px', sm: '16px', md: '18px' },
              letterSpacing: '1px',
              mb: 2,
              textAlign: { xs: 'center', sm: 'left', md: 'left' },
            }}>
            Contact
          </Typography>
          <Typography
            variant='body1'
            sx={{
              fontFamily: 'Poppins',
              color: '#f0f0f0',
              fontWeight: '300',
              fontSize: { xs: '14px', sm: '16px', md: '18px' },
              letterSpacing: '1px',
              textAlign: { xs: 'center', sm: 'left', md: 'left' },
            }}>
            6355 NW 36th St., Virginia Gardens, FL
          </Typography>
          <Typography
            variant='body1'
            sx={{
              fontFamily: 'Poppins',
              color: '#f0f0f0',
              fontWeight: '300',
              fontSize: { xs: '14px', sm: '16px', md: '18px' },
              letterSpacing: '1px',
              textAlign: { xs: 'center', sm: 'left', md: 'left' },
            }}>
            info@metritha.com
          </Typography>
        </Grid>
        {/* Third Section: Image with LinkedIn redirect */}
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <a
            href='https://www.linkedin.com/company/company-name'
            target='_blank'
            rel='noopener noreferrer'>
            <LinkedIn
              sx={{
                fontSize: { xs: '60px', sm: '80px', md: '100px' },
                color: '#0A66C2',
              }}
            />
            {/* Alternatively, use an img tag if you want to include a LinkedIn logo image */}
            {/* <img src={linkedInLogo} alt='LinkedIn' style={{ width: '50px' }} /> */}
          </a>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
